import React from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
const HowUseContent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <h2>
        <span>{t('How to use Hairpaca?')}</span>
      </h2>

      <div className={styles.container}>
        <Image
          className={styles.bigImgItem}
          src={'/images/hairstyle/how_to_use_bg.webp'}
          alt="icon"
          loading="lazy"
          width={0}
          height={0}
          sizes="100vw"
        />

        <div className={styles.descList}>
          <div>
            <span>Find Your Perfect Style</span>
            <h3>Explore a variety of haircut and pick the one that suits your preferences</h3>
          </div>
          <div>
            <span>
              Click &quot;<span style={{ color: '#68F3F6' }}>Try-On</span>&quot; to see how each
              hairstyle looks on you.
            </span>
            <h3>
              {'Use the "Try-On" feature to preview how each hairstyle fits you in real-time'}
            </h3>
          </div>

          <div>
            <span>New Hairstyles, New You</span>
            <h3>Choose your favorite hairstyle and embrace a fresh new look</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowUseContent;
